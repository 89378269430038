import * as React from 'react';
import {
  TeliaP,
  TeliaHeading,
  TeliaRow,
  TeliaLink,
} from '@teliads/components/react';
import { Layout, Grid } from '../components/Layout';
import { Seo } from '../components/Seo';
import '../styles/types.scss';
import { PageProps } from 'gatsby';
import {
  ContentfulPage,
  ContentfulPageIds,
  ContentfulStaticTranslations,
  ContentfulType,
} from '../models/ContentfulTypes';
import { getUrlWithLanguage } from '../utils';
import { useStaticTranslations } from '../hooks/useStaticTranslations';

interface Props extends PageProps {
  pageContext: {
    devices: ContentfulType[];
    language: string;
    page: ContentfulPage;
  };
}

const IndexPage = ({ pageContext }: Props) => {
  const { devices, language, page } = pageContext;
  const content = useStaticTranslations();
  const translation = content[language].nodes?.find(
    node => node.contentful_id === ContentfulStaticTranslations.HowCanIHelpYou
  );
  return (
    <Layout contentfulId={ContentfulPageIds.Types} title={page.title}>
      <Grid>
        <TeliaRow className="column gap center padding--vertical">
          <TeliaHeading tag="h2" variant="display-100">
            {translation?.title}
          </TeliaHeading>
          {devices.map((type, index) => {
            const nextPage = getUrlWithLanguage(
              language,
              type.slug ? `/${type.slug}` : `/${type.contentful_id}`
            );
            return (
              <TeliaLink
                key={index}
                href={nextPage}
                className="telia-link--select"
                silent
                disableVisited
              >
                <article>
                  <TeliaHeading tag="h3" variant="title-100">
                    {type.title}
                  </TeliaHeading>
                  <img src={type.image?.file.url} />
                </article>
              </TeliaLink>
            );
          })}
          <TeliaP>
            {page.heading?.heading} <br />
            <TeliaLink
              className="telia-link--text-only"
              href="#"
              variant="standalone"
              disableVisited
            >
              {page.nextButtonText}
            </TeliaLink>
          </TeliaP>
        </TeliaRow>
      </Grid>
    </Layout>
  );
};

export default IndexPage;
